<template>
    <div
      class="global-basket"
      :class="{'basket-toasted': toasted, 'toasted-opened': toasted && !toastedClosed}"
    >
        <div
          v-if="toasted"
          class="toasted-header"
          @click="onToastedHeaderClick"
        >
          <img v-if="toastedClosed" class="toggle-icon" src="@/assets/images/ehpad/top-path.svg"/>
          <img v-else class="toggle-icon" src="@/assets/images/ehpad/bottom-path.svg"/>
          <div v-if="showTotal" class="price">{{ rounded(onceTotal) }}€</div>
          <div class="blank"></div>
          <div
            v-if="toastedClosed"
            class="next-header-btn"
            @click="onHeaderNextBtnClick"
          >{{ nextLabel }}</div>
        </div>
        <div class="global-basket__body">
            <div class="summary-title" v-if="!toasted">Récapitulatif panier</div>
            <div class="summary-empty" v-if="empty">Votre panier est vide</div>
            <div class="summary-items" v-else>
                <div class="summary-item mt-4" v-if="onceQuantity > 0">
                    <div class="summary-subtitle">Une seule fois</div>
                    <div class="item-price">{{ rounded(onceAmount) }}€</div>
                </div>
                <div v-if="showShippingInfo" class="summary-item mb-0">
                    <div class="item-label">Frais de livraison</div>
                    <div class="item-price">{{ rounded(shippingFees) }}€</div>
                </div>
                <div v-if="showTotal" class="summary-item total mb-0">
                    <div class="item-label">À régler maintenant</div>
                    <div class="item-price">{{ rounded(onceTotal) }}€</div>
                </div>
                <div class="mt-4" v-if="subscriptionQuantity > 0">
                    <div v-if="subscriptionAmountByFrequency">
                        <div class="summary-subtitle">Abonnements</div>
                        <div class="summary-item" v-for="(frequencyAmount, frequency) in subscriptionAmountByFrequency" :key="frequency">
                            <div class="item-label">{{ formatFrequency(frequency) }}</div>
                            <div class="item-price">{{ rounded(frequencyAmount) }}€</div>
                        </div>
                    </div>
                    <div v-else class="summary-item">
                        <div class="item-quantity">{{ subscriptionQuantity }}</div>
                        <div class="item-label">Abonnements</div>
                        <div class="item-price">{{ rounded(subscriptionAmount) }}€</div>
                    </div>
                </div>
            </div>
            <CardRefund
                v-if="$_canUserViewPromotional && !!$_residentPromotionalElligibleType"
                :promotional-type="$_residentPromotionalElligibleType"
                :subscription-amount="subscriptionAmount"
                :once-total="onceTotal"
                class="mt-2"
            />
            <div v-if="showShippingInfo" class="mt-8">
                <div class="summary-subtitle">Adresse d’expédition</div>
                <div class="summary-client">
                    <div class="client-identity">
                        <img class="identity-icon" src="@/assets/images/ehpad/user.svg"/>
                        <div class="identity-name">{{ shippingName }}</div>
                    </div>
                    <div class="client-institution" v-if="shippingAddress">
                        <div class="institution-name">{{ shippingAddress.name }}</div>
                        <div class="institution-address">{{ shippingAddress.address.address1 }}</div>
                        <div class="institution-address">{{ shippingAddress.address.zipPostalCode }} {{ shippingAddress.address.city }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="global-basket__footer">
            <div class="form-check mb-4" v-if="showCgvConsent">
                <div class="check-input">
                  <vs-checkbox
                    :value="cgvConsent"
                    @input="onCgvConsentInput">
                  </vs-checkbox>
                  <div class="check-label text-d">
                    J’ai lu et j’accepte les <a class="cgv-link" href="/legal/cgv" target="_blank">Conditions générales de vente</a>
                  </div>
                </div>
            </div>
            <vs-button
                class="next-btn"
                :disabled="nextDisabled"
                @click="onNextBtnClick"
            >
                {{ nextLabel }}
            </vs-button>
        </div>
    </div>
</template>

<script>
import { getRoundedPrice } from '@/modules/numbers'
import { getFrequencyLabel } from '@/modules/labels'

import residentPromotional from '@/mixins/residentPromotional'
import CardRefund from '@/views/residents/components/CardRefund.vue'

export default {
    name: 'BasketSummary',
    mixins: [
      residentPromotional
    ],
    components: {
        CardRefund
    },
    props: {
        onceQuantity: {
            type: Number,
            required: true
        },
        onceAmount: {
            type: [Number, String],
            required: true
        },
        subscriptionQuantity: {
            type: Number,
            required: true
        },
        subscriptionAmount: {
            type: [Number, String],
            required: true
        },
        subscriptionAmountByFrequency: {
            type: Object,
            default: () => null
        },
        empty: {
            type: Boolean,
            default: false
        },
        showTotal: {
            type: Boolean,
            default: false
        },
        onceTotal: {
            type: [Number, String]
        },
        showShippingInfo: {
            type: Boolean,
            default: false
        },
        shippingName: {
            type: String
        },
        shippingAddress: {
            type: Object
        },
        shippingFees: {
            type: Number
        },
        showCgvConsent: {
            type: Boolean,
            default: false
        },
        cgvConsent: {
            type: Boolean,
            default: false
        },
        nextLabel: {
            type: String,
            default: 'Suivant'
        },
        nextDisabled: {
            type: Boolean,
            default: false
        },
        toasted: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
          toastedClosed: true
        }
    },
    methods: {
        rounded (price) {
          return getRoundedPrice(price)
        },
        formatFrequency (frequency) {
          return getFrequencyLabel(parseInt(frequency, 10))
        },
        onCgvConsentInput (value) {
          this.$emit('update:cgv-consent', value)
        },
        onToastedHeaderClick (e) {
          e.stopPropagation()
          this.toggleToastedBar()
          return false
        },
        toggleToastedBar () {
          this.toastedClosed = !this.toastedClosed
        },
        onHeaderNextBtnClick (e) {
          e.stopPropagation()
          if (this.nextDisabled) {
            this.toastedClosed = false
            return
          }
          this.$emit('next')
          return false
        },
        onNextBtnClick (e) {
          this.$emit('next')
        }
    }
}
</script>

<style lang="scss" scoped>
$padding-side: 22px;

.global-basket {
    max-height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;

    &, * {
        box-sizing: border-box;
    }
    background: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
    color: #2C2C2C;
}
.global-basket__body {
    padding: $padding-side $padding-side $padding-side / 2;
    flex-grow: 1;
    overflow-y: auto;
}
.global-basket__footer {
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: auto;
    padding: $padding-side;
    text-align: center;
    border-top: 1px solid #DDDDDD;
}
.summary-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.summary-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    flex-grow: 1;
}
.summary-empty {
    margin: 22px 0px 0px 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}
.summary-items {

    .summary-item {
        display: flex;
        align-items: center;

        .item-quantity {
            padding: 2px 7px 0px 7px;
            height: 20px;
            display: block;
            border-radius: 20px;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #FFF;
            background-color: #A6A6A6;
        }
        .item-label {
            flex-grow: 1;
            margin: 0px 0px 0px 12px;
            font-size: 16px;
            line-height: 32px;
        }
        .item-price {
            text-align: right;
        }
    }
    .total {
        font-weight: bold;
    }
}
.summary-client {

    .client-identity {
        display: flex;
        align-items: center;
        margin: 14px 0px 0px 0px;

        .identity-icon {
            width: 16px;
        }

        .identity-name {
            flex-grow: 1;
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
        }
    }
    .client-institution {

        .institution-name {
            margin: 8px 0px 0px 0px;
        }

        .institution-address {

        }
    }
}
.next-btn {
    width: 273px;
    max-width: 100%;
}
.check-input {
    display: flex;
    align-items: flex-start;
}
.check-input::v-deep {

    .vs-checkbox {
        flex-shrink: 0;
    }
}

.basket-toasted {
  position: fixed !important;
  left: 0px;
  bottom: 0px;
  width: 100%;
  box-shadow: none;
  border-radius: 8px 8px 0px 0px;
  z-index: 5;

  .toasted-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 52px;
    border-radius: 8px 8px 0px 0px;
    background: rgba(var(--vs-primary), 1);
    cursor: pointer;

    .toggle-icon {
      width: 14px;
      margin: 0px 15px 0px 15px;
    }
    .blank {
      flex-grow: 1;
    }
    .price {
      margin: 0px 15px 0px 0px;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      text-align: right;
      text-transform: uppercase;
      color: white;
    }
    .next-header-btn {
      margin: 0px 15px 0px 0px;
      padding: 20px 0px 20px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      color: white;
    }
  }

   .global-basket__body {
     display: none;
   }
   .global-basket__footer {
     display: none;
   }
}
.toasted-opened {

  .global-basket__body {
    display: block;
  }
  .global-basket__footer {
    display: block;
  }
}
</style>
