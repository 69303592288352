



































import Vue from 'vue'

import Routes from "@/constants/routes"

import { HCard, HPopin, HIcon }  from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HCard,
        HPopin,
    },

    props: {
        promotionalType: {
            type: String,
            required: true,
            validator: val => ['punctual','recurring'].includes(val)
        },
        onceTotal: {
            type: [Number, String]
        },
        subscriptionAmount: {
            type: [Number, String]
        },
    },

    data(){
        return {
            popinLegal: false
        }
    },

    computed: {
        isFunnelPage(): boolean {
            return this.$route.name === Routes.ResidentStoreShipping || this.$route.name === Routes.ResidentStorePayment
        },
        canDisplayComponentInFunnel(): boolean {
            if (this.isFunnelPage) {
                if (this.promotionalType === 'punctual' && this.onceTotal ) {
                    return true
                }
                else if ( (this.promotionalType === 'recurring' || this.promotionalType === 'punctual') && this.subscriptionAmount) {
                    return true
                }
                return false
            }
            return true
        },
        displayPunctualMessageInFunnel(): boolean {
            if (this.promotionalType === 'punctual' && this.onceTotal ) return true
            return false
        },
        refundAmountForPunctual(): string {
            let amount = 10

            if (this.promotionalType === 'punctual' && this.onceTotal ) {
                amount = Number(this.onceTotal)
            }

            return amount < 10 ? amount.toFixed(2) : '10'
        },
        refundDateText(): string {
            if (this.promotionalType === 'punctual' && this.onceTotal) {
                return "avant la fin du mois de décembre."
            }
            return "début février"
        }
    },

    methods: {
        displayLegalContent(): void {
            console.log('')
        },
        togglePopin() {
            this.popinLegal = !this.popinLegal
        }
    }
})
